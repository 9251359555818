import React from 'react'
import Img from 'gatsby-image'
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'gatsby'

SwiperCore.use([Navigation, Pagination, A11y])

const Component = ({item, latestPosts}) => {
  const filteredPosts =
    item?.filter?.length > 0
      ? latestPosts?.nodes?.filter((post) =>
          post.categories.nodes.some((category) =>
            item.filter
              .map((filter) => filter.termTaxonomyId)
              .includes(category.termTaxonomyId)
          )
        )
      : latestPosts?.nodes
  return (
    <div className={`section latest-news`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="title">{item?.title ? item?.title : 'News archive'}</h3>
            <Swiper
              spaceBetween={50}
              slidesPerView={1.3}
              navigation
              pagination={{ clickable: true, dynamicBullets: true }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 80,
                },
              }}
              onSwiper={(swiper) => swiper.update()}
            >
              {filteredPosts?.map((item, i, arr) => (
                <SwiperSlide key={'swipe' + i}>
                  {item?.news?.image?.localFile?.childImageSharp?.fluid && (
                    <div className="news-image">
                      <Img
                        fluid={item.news.image.localFile.childImageSharp.fluid}
                        className="no-padding-image"
                        alt={item.news.image.altText}
                      />
                    </div>
                  )}
                  <Link className="read-more-title" to={item.uri}>
                    <h3>{item?.title}</h3>
                  </Link>
                  <p>
                    {item.news.description}{' '}
                    <Link className="read-more" to={item.uri}>
                      Read more
                    </Link>
                  </p>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
